@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?so8ja8');
  src:  url('fonts/icomoon.eot?so8ja8#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?so8ja8') format('truetype'),
    url('fonts/icomoon.woff?so8ja8') format('woff'),
    url('fonts/icomoon.svg?so8ja8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-games:before {
  content: "\e900";
  color: #fff;
}
.icon-rain-gear-shops:before {
  content: "\e901";
  color: #fff;
}
.icon-resturants:before {
  content: "\e902";
  color: #fff;
}
.icon-rides:before {
  content: "\e903";
  color: #fff;
}
.icon-sign-out:before {
  content: "\e904";
  color: #fff;
}
.icon-users:before {
  content: "\e905";
  color: #fff;
}
.icon-blogs:before {
  content: "\e906";
  color: #fff;
}
.icon-crowd-calendar:before {
  content: "\e907";
  color: #fff;
}
.icon-dashboard:before {
  content: "\e908";
  color: #fff;
}
.icon-extra-magic-hours:before {
  content: "\e909";
  color: #fff;
}
