.table-action-icons{
  text-align: center;
  font-size: 18px;
}

.dates-button{
  position: absolute;
  left: 37%;
  .dates-button__christmas,.dates-button__halloween{
    background: #0CBD12 !important;
    color: #FFFFFF !important;
    border-radius: 4px !important;
    font-size: 12px !important;
    padding: 10px 25px !important;
    height: auto !important;
    z-index: 1;
    margin-right: 15px;
    .anticon{
      font-size: 15px;
    }
  }

  .dates-button__halloween{
    background: #000107 !important;
  }

}
