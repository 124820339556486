.dashboard-cards {
  display: inline-block;
  width: 23%;
  margin-bottom: 25px;
  margin-right: 2.6%;
  text-align: center;
  border-radius: 4px;
  padding: 48px 0;
  box-shadow: 0 6px 24px #00000014;
  &:nth-child(4n){
    margin-right: 0;
  }
  .dashboard-cards__count{
    color: #2D2E2C;
    font-size: 30px;
    font-weight: bold;
  }
  .dashboard-cards__title{
    color:#4F4F4F;
    font-size: 14px;
  }
}
