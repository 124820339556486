.title{
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  width: 50%;
}

.page-actions{
  display: inline-block;
  vertical-align: top;
}

.event-dates{
  margin-top: 50px;
  .event-dates__display{
    display: inline-block;
    width: auto;
    margin: 10px 10px 10px 0;
    background: #F6F6F6;
    color:#1C37E4;
    font-size: 13px;
    padding: 5px 15px;
    border-radius: 30px;
    .anticon{
      margin-left: 5px;
      color: #F72F2F;
    }
  }
}

.event-dates-modal {
  width: 40% !important;
  .event-form-field {
    display: inline-block;
    width: 30%;
    margin-left:12.5%;
    vertical-align: top;
  }
  .form-actions{
    margin-top: 30px;
  }
}
