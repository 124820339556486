.tag-value{
  margin: 10px 10px 35px 0;
  display: inline-block;
  vertical-align: top;
  width: auto;
  padding: 2px 25px;
  border-radius: 30px;
  background: #9A2AE4;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
}
