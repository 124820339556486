.side-nav-bar{
    position: fixed;
    width: 18.5%;
    height: 100vh;
    background: #1C37E4;
    .icon{
        display: inline-block;
        width: 20%;
        vertical-align: middle;
        font-size: 15px;
        margin-right: 10px;
        text-align: center;
    }
    .menu-title{
        display: inline-block;
        width: 70%;
        vertical-align: middle;
    }
    .app-logo{
        width: 50%;
        margin: 30px auto 25px auto;
        img{
            width: 100%;
        }
    }
    .user-details{
        width: 50%;
        margin: 10px auto;
        background: #4165E7;
        padding:10px 0;
        img{
            margin: 0 25%;
            width: 50%;
            text-align: center;
        }
        .username{
            color: #FFFFFF;
            font-size: 12px;
            text-align: center;
        }
    }
    .ant-menu{
        font-size: 12px;
        background: transparent;
        border: unset;
        outline: unset;
        color: #FFFFFF;
        width: 65%;
        margin: auto;
    }
    .ant-menu-item{
        height: 35px !important;
    }
    .ant-menu-item:hover,.ant-menu-item-selected{
        color: #FFFFFF;
        border: unset;
        background: transparent !important;
    }
}
