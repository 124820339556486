.delete-modal{
  width: 30% !important;

  .delete-modal__text{
    text-align: center;
    font-weight: bold;
    font-size: 15px;
  }

  .delete-modal__actions{
    margin-top: 30px;
    margin-bottom: 5px;
    text-align: right;
  }

  .ant-btn-primary,.ant-btn-primary:hover,.ant-btn-primary:focus{
    border: unset;
    background: #F72F2F !important;
    font-size: 12px;
  }
}
