.ant-tabs{
  overflow: visible !important;
}

.ant-tabs-nav::before{
  border: unset !important;
}

.ant-tabs-tab{
  font-size: 16px;
  color: #9E9E9E;
  font-weight: bold;
  &:hover{
    color:#1C37E4 !important;
  }
}

.ant-tabs-tab-active{
  .ant-tabs-tab-btn {
    color:#1C37E4 !important;
    font-weight: bold !important;
  }
}

.ant-tabs-ink-bar {
  background: #1C37E4 !important;
}

.ant-tag {
  border: unset !important;
  margin: auto !important;
  padding: 5px 15px !important;
}

.table-approve-button{
  background: #D5A113 !important;
  border-radius: 4px !important;
  color: #FFFFFF !important;
  border: unset !important;
}

.table-action-icons{
  color:#969696;
  cursor: pointer;
}

.ant-table{
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 6px 24px #00000014;
  border-radius: 4px;
  opacity: 1;
  .ant-table-content{
    padding: 0 15px;
  }
  th{
    color:#C8C8C8 !important;
    background: transparent !important;
  }
  tbody{
    font-size: 12px;
    td{
      color:#4F4F4F;
      padding: 12px 16px !important;
    }
  }
}
