@import "src/styles/variables";

.blog-search,.blog-search:hover{
  right: 16%;
  margin-top: -3px !important;
}


.blogs__form-field{
  margin: 10px 0;
  width: 45%;
  .ant-select-selector,.ant-input{
    background: #F6F6F6 !important;
    color: #313131 !important;
    font-weight: bold;
  }
  .image-container{
    .banner-thumbnail{
      width: 100%;
    }
  }
  
}

.blogs__title{
  color: $primary-color;
  font-weight: 500;
  font-size: 16px;
}

.blogs__editor{
  margin: 15px 0;
}


.blog-status{
  padding: 10px;
  border-radius: 4px;
}

.green{
  color:#0FA60A;
  background: #0FA60A25;
}

.red{
  color: #E41313;
  background: #E4131325;
}
