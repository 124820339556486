.upload-button{
  font-size: 10px;
  .anticon{
    font-size: 14px;
  }
  i{
    font-size: 8px;
  }
}

.ant-upload{
  border-radius: 5px;
  background: #E3E3E3;
}

.ant-upload-select-picture-card{
  border:unset !important;
}
