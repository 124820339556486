.bulk-upload-modal{
  .bulk-upload-modal__content{
    padding: 0 25px;
  }
  .bulk-upload-modal__title,.bulk-upload-modal__template{
    display: inline-block;
    width: 50%;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
  }
  .bulk-upload-modal__template{
    text-align: right;
    font-weight: normal;
    color:#4BAEF8;
    font-size: 13px;
    cursor: pointer;
    .anticon{
      margin-left: 5px;
    }
  }
  .bulk-upload-modal__file-selection{
    margin-top: 20px;
    .ant-upload.ant-upload-drag{
      border: unset;
    }
    .ant-upload-text{
      color: #A9A9A9 !important;
      padding: 25px 0;
    }
  }

  .bulk-upload-modal__actions{
    text-align: right;
    margin-top: 20px;
    .bulk-upload-modal__actions--validate {
      background: #1C37E4;
    }
    .bulk-upload-modal__actions--cancel {
      background: #EEEEEE;
      color: #929090;
      margin-right: 15px;
      font-size: 12px;
    }
  }

  .bulk-upload-modal__progress{
    margin-bottom: 40px;
    .bulk-upload-modal__progress--text{
      text-align: right;
      color:#4F4F4F;
      font-size: 15px;
      font-weight: bold;
    }
    .bulk-upload-modal__progress--bar{
      .ant-progress-outer{
        padding-right: 0;
        .ant-progress-inner{
          border-radius: 0;
          .ant-progress-bg{
            height: 20px !important;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .bulk-upload-modal__errors{
    margin-top: 10px;
    overflow: auto;
    height: 60vh;
    .bulk-upload-modal__errors--card{
      margin: 10px 0;
      background: #FFC6C6;
      border-radius: 4px;
      color: #F72F2F;
      font-size: 16px;
      padding: 10px 20px;
      width: 90%;
      .anticon{
        font-size: 24px;
        vertical-align: middle;
        margin-right: 20px;
      }
    }
  }
}
