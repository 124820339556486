.ride-details{
  margin: 30px 0;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 6px 24px #00000014;
  border-radius: 4px;
  padding: 50px 20px 50px 50px;
  .show-textarea{
    width: 100%;
  }
  .photo-pass-description{
    width: 64%;
    margin-right: 2%;
    display: inline-block;
    vertical-align: top;
  }
  .photo-pass{
    display: inline-block;
    width: 33%;
    vertical-align: top;
    img{
      width: 80%;
      margin: auto;
      border-radius: 3px;
    }
  }
  .photos-for-ride{
    margin: 40px 0;
    img{
      margin-top: 10px;
      width: 19%;
      &:not(:last-child){
        margin-right: 1.25%;
      }
    }
  }
  .ride-location-details{
    margin-top: 10px;
    .ride-location-details__title{
      font-weight: bold;
      font-size: 16px;
      color:#1C37E4;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    .ride-location-details__field{
      display: inline-block;
      width: 33%;
      vertical-align: top;
      .value{
        margin-bottom: 0;
      }
    }
    .ride-id{
      color:#482AE4;
      font-size: 10px;
    }
  }

  .ride-form{
    display: inline-block;
    width: 33%;
    vertical-align: top;
    margin-bottom: 40px;
    .ant-select,.ant-input{
      width: 90%;
    }
    .ant-select-selector,.ant-input{
      background: #F6F6F6 !important;
      color: #313131 !important;
      font-weight: bold;
      border:unset !important;
    }
    .label{
      margin-bottom: 10px;
    }
    .value{
      margin-bottom: 0;
    }
  }
  .ride-form.textarea{
    width: 100%;
    .ant-input{
      width: 95.5%;
    }
  }
  .ride-form.multi-select{
    width: 50%;
    .ant-select-selection-item{
      padding: 0 25px;
      border-radius: 30px;
      background: #9A2AE4;
      color: #FFFFFF;
    }
  }

  .image-upload-container{
    background: #F6F6F6;
    padding: 15px 10px 0 10px;
    margin: 10px 0 35px 0;
    width: 95%;
    .ant-upload-list-item-actions{
      a{
        display:none;
      }
    }
  }

  .form-actions{
    text-align: right;
    .ant-btn-primary,.ant-btn-primary:hover,.ant-btn-primary:focus{
      border: unset;
      background: #1B9712;
      font-size: 12px;
    }
  }
}

