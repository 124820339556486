.value{
  margin-bottom: 20px !important;
}

.rain-gear-form{
  display: inline-block;
  width: 45%;
  margin-right: 5%;
  margin-bottom: 20px;
  vertical-align: top;
}
