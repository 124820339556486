@import "src/styles/variables";

.hidden-mickey__modal{
  .ant-modal-body {
    padding: 40px;
  }
}

.hidden-mickey__title{
  color: $primary-color;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 35px;
}

.hidden-mickey__section{
  display: inline-block;
  vertical-align: top;
  width: 48%;
  margin-right: 4%;
  &:nth-child(2n){
    margin-right: 0;
  }
}

.hidden-mickey__image img{
  width: 75%;
}

.hidden-mickey__form-field{
  font-size: 14px;
  margin: 15px 0;
  .ant-select-selector,.ant-input{
    background: #F6F6F6 !important;
    color: #313131 !important;
    font-weight: bold;
  }
}

.hidden-mickey__form-submit.ant-btn-primary,.hidden-mickey__form-submit.ant-btn-primary:hover {
  background: #0CBD12 !important;
}
