.ant-input-search,.ant-input-search:hover{
  position: absolute;
  width: 20% !important;
  right: 5%;
  vertical-align: middle !important;
  margin-top: 5px !important;
  z-index: 1;
  border: 1px solid #d9d9d9!important;

  .ant-input{
    height: 45px;
  }

  .ant-input-group-addon{
    position: absolute;
    top: 6px;
    bottom: 0;
    right: 50px;
    z-index: 2;
    left:unset !important;
  }

}

.ant-input-search-with-button input{
  border: unset;
  background: #EEEEEE;
}


.value{
   margin-bottom: 0 !important;
}

.modal-title{
  font-size: 16px;
  color: #1C37E4;
  font-weight: bold;
  margin-top: 20px;
  margin-left: 20px;
}

.inline-fields{
  display: inline-block;
  width: 20%;
  vertical-align: top;
}

.user-details{
  margin: 25px 0 0 20px;
}

.user-details__ride-tags,.user-details__food-tags{
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  width: auto;
  margin-right: 5px;
  border-radius: 30px;
  padding: 2px 15px;
  margin-top: 10px;
}

.user-details__ride-tags{
  background: #E4721C;
}
.user-details__food-tags{
  background: #9A2AE4;
}
