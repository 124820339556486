@import "src/styles/variables";

.component-styles{
  margin-left: 22%;
  padding-top: 5%;
  margin-right: 5%;
}

.add-new-ride{
  position: absolute !important;
  right: 5% !important;
  background: #1C37E4 !important;
  color: #FFFFFF !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  padding: 10px 25px !important;
  height: auto !important;
  z-index: 1;
}

.back-button{
  font-size: 16px;
  margin-bottom: 20px;
  color:#1C37E4;
  font-weight: bold;
  cursor: pointer;
  .anticon{
    margin-right: 10px;
    color:#1C37E4;
    vertical-align: bottom;
    font-size: 25px;
  }
}

.approve-actions{
  text-align: right;
  margin-top: 20px;
  .ant-btn-text{
    margin-right: 10px;
    color:#B9B9B9;
    font-size: 12px;
  }
  .ant-btn-primary,.ant-btn-primary:hover,.ant-btn-primary:focus{
    border: unset;
    background: #1B9712;
    font-size: 12px;
  }
}

.ant-modal{
  width:60% !important;
  .ride-title{
    color:#1C37E4;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
  }
  .modal-tag{
    font-size: 15px;
    text-align: center;
    margin-top: 5px;
  }
  .ant-btn-primary,.ant-btn-primary:hover,.ant-btn-primary:focus{
    border: unset;
    background: #1B9712;
    font-size: 12px;
  }

  .ride-form{
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    width: 48%;
    margin: 10px 0;
    &:nth-child(odd){
      margin-right: 4%;
    }
    .ant-select-selector,.ant-input{
      background: #F6F6F6 !important;
      color: #313131 !important;
      font-weight: bold;
    }
  }
  .form-actions{
    text-align: right;
    margin: 15px 0;
  }
  .ant-select{
    width: 100%;
  }
  .ant-modal-close-x{
    position: absolute;
    background: #F72F2F;
    right: -15px;
    top: -15px;
    color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 31px;
    font-size: 14px;
  }
}

.image-container{
  display: inline;
  vertical-align: top;
  margin-right: 15px;
  position: relative;
}

.image-delete-icon{
  position: absolute;
  color: #FFFFFF;
  background: $danger-color;
  right: -10px;
  width: 25px;
  height: 25px;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
