.login-image,.login-form{
  display: inline-block;
  vertical-align: top;
  width: 50%;
  img{
    height: 100vh;
    width: 100%;
  }
  overflow: hidden;
}

.login-form{
  margin-top: 20vh;
  padding: 0 11%;
  .login-form__tag{
    color:#1C37E4;
    font-size: 16px;
  }
  .login-form__title{
    font-size: 26px;
    font-weight: bold;
    color: #000000;
  }
  .login-form__field{
    margin-top: 20px;
  }
  .ant-input{
    border-radius: 3px;
    background: #F6F6F6;
    border: unset;
    padding: 10px;
  }
  .login-form__options{
    margin-top: 15px;
    color:#ACACAC;
    font-size: 12px;
    div{
      float: right;
      cursor: pointer;
    }
    .login-form__options--checkbox{
      font-size: 12px;
      color:#ACACAC;
      .ant-checkbox-checked .ant-checkbox-inner{
        background: #1C37E4 !important;
      }
    }
  }
  .login-form__submit,.login-form__submit:hover,.login-form__submit:active,.login-form__submit:focus{
    width: 100%;
    background: #1C37E4;
    color: #FFFFFF;
    border-radius: 4px;
    margin-top: 50px;
    padding: 10px 0;
    height: auto;
    border: unset;
  }
}
