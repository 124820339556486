.restaurant-modal{
  width: 30% !important;
  .ride-form{
    display: block;
    width: 80%;
    margin:20px 0 30px;
  }
}

.restaurant-bulk-upload-button{
  right: 18% !important;
}
