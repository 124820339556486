@import "variables";
@import "helpers";
@import url('https://fonts.googleapis.com/css?family=Jost:200,400,500,600,700,900');


/* General component styles */
body {
  background-color: #FFFFFF;
  color: $primary-black;
}

body, h1, h2, h3, h4, h5, button, input {
  font-family: $app-primary-font;
}

.label{
  color: #ACACAC;
  font-size: 12px;
}

.value{
  color: #313131;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 45px;
  word-break: break-word;
}

.ant-btn-primary,.ant-btn-primary:hover{
  background: $primary-color !important;
}

.ant-tabs-tab{
  font-weight: 500 !important;
}

.main-loader{
  position: absolute;
  top: 40vh;
  bottom: 0;
  left: 15%;
  right: 0;
  margin: auto;
  z-index: 1;
}
