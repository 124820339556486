@import "src/styles/variables";

.games-tabs{
  position: relative;
}

.game-button{
  position: absolute !important;
  top: 0;
  right: 0;
  border-radius: 4px !important;
  font-size: 12px !important;
  padding: 10px 25px !important;
  z-index: 1;
  height: auto !important;
}

.trivia-card{
  width: 40%;
  box-shadow: 0 6px 24px #00000014;
  border-radius: 4px;
  padding: 15px 20px;
}

.trivia-card__image{
  display: inline-block;
  vertical-align: middle;
  width: 20%;
  text-align: center;
  img{
    width: 90%;
  }
}

.trivia-card__details{
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  margin-left: 5%;
  font-size: 14px;
}

.trivia-card__title{
  color: #4F4F4F;
  font-weight: 500;
}

.trivia-card__count{
  color:$primary-color;
  font-weight: 500;
}
